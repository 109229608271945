import { Navigate, Route, Routes } from "react-router-dom";
import AuthGuard from "./AuthGuard";
import Login from "components/Login";
import Home from "components/Home";
import UploadBase from "components/UploadBase";
import UnSub from "components/UnSub";
import Mis from "components/Mis";
import NumberDetail from "components/NumberDetail";
import SubscriptionDetail from "components/SubscriptionDetail";
import RoleGuard from "./RoleGuard";
import DND from "components/DND";

const AppRoutes = () => {
    return (
        <Routes>
            <Route element={<AuthGuard isProtected={false} />}>
                <Route path="/login" element={<Login />} />
            </Route>
            <Route element={<AuthGuard />} isProtected>
                <Route element={<RoleGuard roles={["admin"]} />}>
                    <Route path="/" Component={Home} />
                    <Route path="/home" Component={Home} />
                    <Route path="/sub-details" Component={SubscriptionDetail} />
                    <Route path="/upload-base" Component={UploadBase} />
                    <Route path="/dnd" Component={DND} />
                    <Route path="/mis" Component={Mis} />
                </Route>
                <Route element={<RoleGuard roles={["admin", "cci"]} />}>
                    <Route path="/number-detail" Component={NumberDetail} />
                    <Route path="/un-sub" Component={UnSub} />
                </Route>
            </Route>
            <Route path="*" element={<Navigate to="/login" />} />
        </Routes>
    )
}

export default AppRoutes;
