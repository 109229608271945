const { useSession } = require("providers/SessionProvider");
const { Outlet, Navigate } = require("react-router-dom");

const RoleGuard = ({ roles }) => {
    const { user } = useSession();

    if (!user.id) {
        return null;
    }

    return (
        roles.includes(user.role)
            ? <Outlet /> : <Navigate to={user.role === "cci" ? "/number-detail" : "/home"} />
    );
}

export default RoleGuard;
