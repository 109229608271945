import { useSession } from 'providers/SessionProvider';
import React from 'react';
import { NavLink, useLocation } from 'react-router-dom';

const Layout = (props) => {
    const { user, logout } = useSession();
    const location = useLocation();

    if (user.role === "cci") {
        return (
            <>
                <header>
                    <ul>
                        <li><NavLink to="/number-detail">Number Detail</NavLink></li>
                        <li><NavLink to="/un-sub">Unsub</NavLink></li>
                        <li><NavLink to="/login" onClick={logout}>Logout</NavLink></li>
                        {/* <li style="float:right"><a class="active" href="#about">About</a></li> */}
                    </ul>
                </header>
                {location.pathname === ('/upload-base' || '/un-sub' || '/dnd') && <div style={{ marginTop: '50px' }}></div>}
                {/* <div> */}
                {props.children}
                {/* </div> */}

            </>
        )
    } else if (user.role === "admin") {
        return (
            <>
                <header>
                    <ul>
                        <li><NavLink to="/home">Home</NavLink></li>
                        <li><NavLink to="/upload-base">Upload Base</NavLink></li>
                        <li><NavLink to="/dnd">DND</NavLink></li>
                        <li><NavLink to="/number-detail">Number Detail</NavLink></li>
                        <li><NavLink to="/un-sub">Unsub</NavLink></li>
                        <li><NavLink to="/mis">MIS</NavLink></li>
                        <li><NavLink to="/login" onClick={logout}>Logout</NavLink></li>
                        {/* <li style="float:right"><a class="active" href="#about">About</a></li> */}
                    </ul>
                </header>
                {location.pathname === ('/upload-base' || '/un-sub' || '/dnd') && <div style={{ marginTop: '50px' }}></div>}
                {/* <div> */}
                {props.children}
                {/* </div> */}

            </>
        )
    } else {
        return (
            <>
                <header>
                    <ul>
                        <li><NavLink to="/login" onClick={logout}>Logout</NavLink></li>
                    </ul>
                </header>
                <p>User role not found!</p>
            </>

        )
    }
}

export default Layout