import React from "react";
import Layout from "./layout/Layout";
import { useNumberDetail } from "../providers/NumberDetailProvider";

const NumberDetail = () => {
  const { subscriptions, billing, getNumberDetails } = useNumberDetail();

  async function handleSubmit(e) {
    e.preventDefault();

    const formData = new FormData(e.currentTarget);

    try {
      await getNumberDetails(formData.get("mobileNumber"));
    } catch (error) {
      console.log("Inside failure", error);
    }
  }

  return (
    <>
      <Layout>
        <div className="card" align="center">
          <div className="card-body">
            <div className="rows">
              <form onSubmit={handleSubmit}>
                <input
                  name="mobileNumber"
                  type="text"
                  placeholder="Enter 11 digits number."
                  required
                />
                <button
                  name="submit"
                  type="submit"
                  className="btn btn-primary waves-light"
                >
                  Search
                </button>
              </form>
            </div>
          </div>
        </div>

        {/* <!-- ################ TBL SUB ##################### --> */}
        <div
          style={{
            fontWeight: "bold",
            textAlign: "center",
          }}
        >
          <br />
          <p
            style={{
              fontWeight: "bold",
              textAlign: "center",
            }}
          >
            [ SUB Status ]
          </p>
        </div>
        <div className="rows">
          <div className="containers table-responsive">
            <table
              id="tblReq"
              className="display nowrap ns z-depth-1 table table-striped table-bordered row-border order-column table-hover"
              style={{
                width: "100%",
              }}
            >
              <thead>
                <tr>
                  <th className="black-text text-darken-2">Date</th>
                  <th className="black-text text-darken-2">Msisdn</th>
                  <th className="black-text text-darken-2">Mode</th>
                  <th className="black-text text-darken-2">Service</th>
                  <th className="black-text text-darken-2">Status</th>
                </tr>
              </thead>
              <tbody>
                {subscriptions.map(record => (
                  <tr key={record.id}>
                    <td className="ns" style={{ textAlign: "center" }}>
                      {record.date}
                    </td>
                    <td className="ns" style={{ textAlign: "center" }}>
                      {record.mobileNumber}
                    </td>
                    <td className="ns" style={{ textAlign: "center" }}>
                      {record.mode}
                    </td>
                    <td className="ns" style={{ textAlign: "center" }}>
                      {record.service}
                    </td>
                    <td className="ns" style={{ textAlign: "center" }}>
                      {record.status}
                    </td>
                  </tr>
                ))}
              </tbody>
            </table>
            {!subscriptions.length && (<p className="no-records">No records found</p>)}
          </div>
        </div>

        {/* <!-- ################## TBL BILLING ################### --> */}
        <div
          style={{
            fontWeight: "bold",
            textAlign: "center",
          }}
        >
          <p
            style={{
              fontWeight: "bold",
              textAlign: "center",
            }}
          >
            [ Billing Status ]
          </p>
        </div>
        <div className="rows">
          <div className="containers table-responsive">
            <table
              id="tblBilling"
              className="display nowrap ns z-depth-1 table table-striped table-bordered row-border order-column table-hover"
              style={{ width: "100%" }}
            >
              <thead>
                <tr>
                  <th className="black-text text-darken-2">Date</th>
                  <th className="black-text text-darken-2">Msisdn</th>
                  <th className="black-text text-darken-2">Mode</th>
                  {/* <th className="black-text text-darken-2">Action</th> */}
                  <th className="black-text text-darken-2">amount</th>
                  <th className="black-text text-darken-2">status</th>
                  <th className="black-text text-darken-2">msg</th>
                </tr>
              </thead>
              <tbody>
                {billing.map(record => (
                  <tr key={record.id}>
                    <td className="ns" style={{ textAlign: "center" }}>
                      {record.date}
                    </td>
                    <td className="ns" style={{ textAlign: "center" }}>
                      {record.mobileNumber}
                    </td>
                    <td className="ns" style={{ textAlign: "center" }}>
                      {record.mode}
                    </td>
                    {/* <td className="ns" style={{ textAlign: "center" }}>
                      {record.action}
                    </td> */}
                    <td className="ns" style={{ textAlign: "center" }}>
                      {record.amount}
                    </td>
                    <td className="ns" style={{ textAlign: "center" }}>
                      {record.status}
                    </td>
                    <td className="ns" style={{ textAlign: "center" }}>
                      {record.message}
                    </td>
                  </tr>
                ))}
              </tbody>
            </table>
            {!billing.length && (<p className="no-records">No records found</p>)}
          </div>
        </div>

        {/* <!-- ################ TBL REQ ##################### --> */}
        <div
          style={{
            fontWeight: "bold",
            textAlign: "center",
          }}
        >
          <br />
          <p
            style={{
              fontWeight: "bold",
              textAlign: "center",
            }}
          >
            [ Ussd & SMS Requests ]
          </p>
        </div>
        <div className="rows">
          <div className="containers table-responsive">
            {/* <!--Table--> */}
            <table
              id="tblSms"
              className="display nowrap ns z-depth-1 table table-striped table-bordered row-border order-column table-hover"
              style={{ width: "100%" }}
            >
              <thead>
                <tr>
                  <th className="black-text text-darken-2">Date</th>
                  <th className="black-text text-darken-2">Msisdn</th>
                  <th className="black-text text-darken-2">Mode</th>
                  <th className="black-text text-darken-2">MO</th>
                </tr>
              </thead>
              <tbody></tbody>
            </table>
            <p className="no-records">No records found</p>
          </div>
        </div>

        {/* <!-- ################ TBL SMS ##################### --> */}
        <div
          style={{
            fontWeight: "bold",
            textAlign: "center",
          }}
        >
          <p
            style={{
              fontWeight: "bold",
              textAlign: "center",
            }}
          >
            [ Messages]
          </p>
        </div>
        <div className="rows">
          <div className="containers table-responsive">
            {/* <!--Table--> */}
            <table
              id="tblMessage"
              className="display nowrap ns z-depth-1 table table-striped table-bordered row-border order-column table-hover"
              style={{ width: "100%" }}
            >
              <thead>
                <tr>
                  <th className="black-text text-darken-2">Date</th>
                  <th className="black-text text-darken-2">Msisdn</th>
                  <th className="black-text text-darken-2">Message</th>
                </tr>
              </thead>
              <tbody></tbody>
            </table>
            <p className="no-records">No records found</p>
          </div>
        </div>
        {/* <!--Table--> */}
      </Layout>
    </>
  );
};

export default NumberDetail;
