import React from "react";
import Layout from "../components/layout/Layout";
import Navbar from "./layout/Navbar";
import { useHome } from "../providers/HomeProvider";
import { Link } from "react-router-dom";

const Home = () => {
  const { homeRecords, updateFilter } = useHome();

  return (
    <>
      <Layout>
        <Navbar updateFilter={updateFilter} />
        <div style={{ marginTop: '50px' }}></div>
        <div className="rows">
          <div className="containers table-responsive">
            {/* <!--Table--> */}
            <table
              className="display nowrap ns z-depth-1 table table-striped table-bordered row-border order-column table-hover"
              style={{ width: "100%" }}
            >
              <thead>
                <tr>
                  <th className="black-text text-darken-2" rowSpan={2}>Date</th>
                  <th className="black-text text-darken-2" rowSpan={2}>Total Base</th>
                  <th className="black-text text-darken-2" rowSpan={2}>Active Base</th>
                  <th className="black-text text-darken-2" colSpan={2}>Unsub</th>
                  <th className="black-text text-darken-2" colSpan={2}>New Subscriptions</th>
                  <th className="black-text text-darken-2" rowSpan={2}>Total New Subscriptions</th>
                  <th className="black-text text-darken-2" colSpan={2}>Charged Subs</th>
                  <th className="black-text text-darken-2" rowSpan={2}>Total New Charged Subs</th>
                  <th className="black-text text-darken-2" rowSpan={2}>Total New Sub Revenue</th>
                  <th className="black-text text-darken-2" colSpan={2}>Renewals</th>
                  <th className="black-text text-darken-2" colSpan={2}>One Time Purchase</th>
                  <th className="black-text text-darken-2" rowSpan={2}>Total Renewal</th>
                  <th className="black-text text-darken-2" rowSpan={2}>Total One Time Purchase</th>
                  <th className="black-text text-darken-2" rowSpan={2}>Total Renewal Revenue</th>
                  <th className="black-text text-darken-2" rowSpan={2}>Total One Time Purchase Revenue</th>
                  <th className="black-text text-darken-2" rowSpan={2}>Total Revenue</th>
                </tr>
                <tr>
                  <th className="black-text text-darken-2">Weekly</th>
                  <th className="black-text text-darken-2">Daily</th>
                  <th className="black-text text-darken-2">Weekly</th>
                  <th className="black-text text-darken-2">Daily</th>
                  <th className="black-text text-darken-2">Weekly</th>
                  <th className="black-text text-darken-2">Daily</th>
                  <th className="black-text text-darken-2">Weekly</th>
                  <th className="black-text text-darken-2">Daily</th>
                  <th className="black-text text-darken-2">Weekly</th>
                  <th className="black-text text-darken-2">Daily</th>
                </tr>
              </thead>
              <tbody>
                {homeRecords.map(record => (
                  <tr key={record.date}>
                    <td className="ns" style={{ textAlign: "center" }}>
                      {record.date}
                    </td>
                    <td className="ns" style={{ textAlign: "center" }}>
                      {record.total_base}
                    </td>
                    <td className="ns" style={{ textAlign: "center" }}>
                      {record.active_base}
                    </td>
                    <td className="ns" style={{ textAlign: "center" }}>
                      {record.unsub_weekly}
                    </td>
                    <td className="ns" style={{ textAlign: "center" }}>
                      {record.unsub_daily}
                    </td>
                    <td className="ns" style={{ textAlign: "center" }}>
                      {record.sub_weekly}
                    </td>
                    <td className="ns" style={{ textAlign: "center" }}>
                      {record.sub_daily}
                    </td>
                    <td className="ns" style={{ textAlign: "center" }}>
                      <Link to={`/sub-details?date=${record.date}`} style={(record.sub_daily || record.sub_weekly) ? { color: "#337ab7", textDecoration: "underline" } : {}}>
                        {record.sub_daily + record.sub_weekly}
                      </Link>
                    </td>
                    <td className="ns" style={{ textAlign: "center" }}>
                      {record.charged_weekly}
                    </td>
                    <td className="ns" style={{ textAlign: "center" }}>
                      {record.charged_daily}
                    </td>
                    <td className="ns" style={{ textAlign: "center" }}>
                      {record.charged_weekly + record.charged_daily}
                    </td>
                    <td className="ns" style={{ textAlign: "center" }}>
                      {record.sub_revenue}
                    </td>
                    <td className="ns" style={{ textAlign: "center" }}>
                      {record.renewal_weekly}
                    </td>
                    <td className="ns" style={{ textAlign: "center" }}>
                      {record.renewal_daily}
                    </td>
                    <td className="ns" style={{ textAlign: "center" }}>
                      {record.one_time_weekly}
                    </td>
                    <td className="ns" style={{ textAlign: "center" }}>
                      {record.one_time_daily}
                    </td>
                    <td className="ns" style={{ textAlign: "center" }}>
                      {record.renewal_weekly + record.renewal_daily}
                    </td>
                    <td className="ns" style={{ textAlign: "center" }}>
                      {record.one_time_weekly + record.one_time_daily}
                    </td>
                    <td className="ns" style={{ textAlign: "center" }}>
                      {record.renewal_revenue}
                    </td>
                    <td className="ns" style={{ textAlign: "center" }}>
                      {record.one_time_revenue}
                    </td>
                    <td className="ns" style={{ textAlign: "center" }}>
                      {record.sub_revenue + record.renewal_revenue + record.one_time_revenue}
                    </td>
                  </tr>
                ))}
              </tbody>
              <tfoot>
                <tr>
                  <td colSpan={14}>
                    Showing {homeRecords.length} of {homeRecords.length} record(s)
                  </td>
                </tr>
              </tfoot>
            </table>
          </div>
        </div>
      </Layout>
    </>
  );
};

export default Home;
