import api from './interceptor';

export const subscribeUsersBatch = async (file, plan) => {
  try {
    const formData = new FormData();
    formData.append('file', file);
    formData.append('packId', plan);
    const response = await api.post(`user/batch/subscribe-users`, formData, {
      headers: {
        'Content-Type': 'multipart/form-data'
      },
    });
    return response.data;
  } catch (e) {
    console.log(e.message);
  }
};

export const unsubscribeUsersBatch = async (file, ise2codeArr) => {
  try {
    const formData = new FormData();
    formData.append('file', file);
    formData.append('ise2codeArr', ise2codeArr);

    const response = await api.post(`user/batch/unsubscribe-users`, formData, {
      headers: {
        'Content-Type': 'multipart/form-data'
      },
    });
    return response.data;
  } catch (e) {
    console.log(e.message);
  }
};

export const addDndUsers = async (file) => {
  try {
    const formData = new FormData();
    formData.append('file', file);

    const response = await api.post(`user/batch/dnd-users`, formData, {
      headers: {
        'Content-Type': 'multipart/form-data'
      },
    });
    return response.data;
  } catch (e) {
    console.log(e.message);
  }
};
